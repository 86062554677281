import { getBoundaries } from 'common/drawing'
import TextSVG from 'common/drawing/TextSVG'

import { MultilineTextNode, TextOnPathNode } from '../types'
import Part from './Part'

export default class TextPart extends Part {
  protected svg: TextSVG | undefined

  public override getBoundaries() {
    const image = this.imageAsCanvas()

    if (!image) return { x: 0, y: 0, width: 0, height: 0 }

    const boundaries = getBoundaries(image)

    const scale = this.getAttr('nodeData').scale

    return {
      x: boundaries.x * scale,
      y: boundaries.y * scale,
      width: boundaries.width * scale,
      height: boundaries.height * scale,
    }
  }

  protected applyNeon(image: HTMLImageElement, node: MultilineTextNode | TextOnPathNode) {
    const shadowPaddingX = 100
    const shadowPaddingY = 100

    const bufferCanvas = document.createElement('canvas')
    bufferCanvas.width = 2 * shadowPaddingX + image.width
    bufferCanvas.height = 2 * shadowPaddingY + image.height
    const bufferContext = bufferCanvas.getContext('2d')!

    bufferContext.drawImage(image, shadowPaddingX, shadowPaddingY, image.width, image.height)
    bufferContext.globalCompositeOperation = 'source-in'
    bufferContext.fillStyle = 'white'
    bufferContext.fillRect(0, 0, bufferCanvas.width, bufferCanvas.height)

    const canvas = document.createElement('canvas')
    canvas.width = bufferCanvas.width
    canvas.height = bufferCanvas.height
    const context = canvas.getContext('2d')!
    context.drawImage(bufferCanvas, 0, 0)
    context.globalCompositeOperation = 'overlay'

    const drawShadow = (color: string, blur: number, opacity: number) => {
      context.shadowOffsetX = -bufferCanvas.width
      context.shadowBlur = blur
      context.shadowColor = color
      context.globalAlpha = opacity

      context.drawImage(bufferCanvas, bufferCanvas.width, 0, bufferCanvas.width, bufferCanvas.height)
    }

    const color = node.color?.hex ?? 'white'
    drawShadow(color, 80, 0.5)
    drawShadow(color, 30, 0.5)
    drawShadow(color, 40, 0.5)
    drawShadow(color, 55, 0.5)
    drawShadow(color, 100, 0.5)
    drawShadow('white', 50, 0.5)
    drawShadow('white', 15, 0.5)
    drawShadow('white', 8, 1)

    this.offset({ x: shadowPaddingX * node.scale, y: shadowPaddingY * node.scale })

    return canvas
  }
}
