import { UserRole } from '@packages/types'
import React from 'react'

import useCurrentUser from '../hooks/useCurrentUser'

function withRoles<TProps>(Component: React.ComponentType<TProps>, AlternateComponent?: () => JSX.Element) {
  return (props: TProps & { allowed?: UserRole[] }) => {
    const { currentUser } = useCurrentUser()

    if (!props.allowed || (currentUser?.role && props.allowed.includes(currentUser?.role))) {
      return <Component role={currentUser?.role} {...props} />
    }

    return AlternateComponent ? <AlternateComponent /> : null
  }
}

export default withRoles
