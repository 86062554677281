import { FieldInputType } from '@packages/types'
import classNames from 'classnames'
import React, { useState } from 'react'

import { useThemeSettings } from 'customizer/theme'

import QuoteTranslation from '../QuoteTranslation'

import './QuoteFormField.scss'

interface FormFieldProps {
  labelField: string
  name: string
  id: string
  type: FieldInputType
  value?: string
  handleChange: (value: string) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>) => void
  placeholder?: string
  maxLength?: number
  error?: string | null
}

const FormField = ({ labelField, type, id, error, onBlur, value, handleChange, ...inputProps }: FormFieldProps) => {
  const textInputStyle = useThemeSettings('textInputAndDropdown')
  const questionStyle = useThemeSettings('question')
  const questionPanelStyle = useThemeSettings('questionPanel')
  const [isHover, setIsHover] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [val, setVal] = useState(value || '')

  const inputStyle: React.CSSProperties = {
    boxShadow: isHover || isActive ? `inset 0 0 0 1px ${textInputStyle.fieldBorderColor}` : 'unset',
    backgroundColor: textInputStyle.fieldBackgroundColor,
    borderRadius: textInputStyle.fieldBorderRadius,
    borderColor: textInputStyle.fieldBorderColor,
    color: textInputStyle.fieldFontColor,
    fontFamily: textInputStyle.fontFamily?.fontFamily,
    outline: 'none',
  }

  const mouseHandler = {
    onMouseEnter: () => setIsHover(true),
    onMouseLeave: () => setIsHover(false),
    onFocus: () => setIsActive(true),
    onBlur: (event: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>) => {
      setIsActive(false)
      onBlur?.(event)
    },
  }

  return (
    <div
      className={classNames('quote-form__field', {
        'quote-form__field--error': !!error,
      })}
      style={{
        color: questionStyle.questionFontColor,
        fontFamily: questionStyle.questionFontFamily?.fontFamily,
      }}
    >
      <label htmlFor={id}>
        <QuoteTranslation field={labelField} />
      </label>
      {type === 'textarea' ? (
        <textarea
          className="quote-form__input"
          id={id}
          style={inputStyle}
          value={val}
          onChange={e => {
            setVal(e.target.value)
            handleChange(e.target.value)
            e.preventDefault()
          }}
          {...mouseHandler}
          {...inputProps}
        />
      ) : (
        <input
          className="quote-form__input"
          id={id}
          style={inputStyle}
          value={val}
          onChange={e => {
            setVal(e.target.value)
            handleChange(e.target.value)
          }}
          {...mouseHandler}
          {...inputProps}
        />
      )}
      {error && (
        <span
          className="quote-form__error"
          style={{ color: questionPanelStyle.errorColor }}
          aria-label="Form field error message"
        >
          {error}
        </span>
      )}
    </div>
  )
}

export default FormField
