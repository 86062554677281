import { FloatingPortal } from '@floating-ui/react'
import classNames from 'classnames'
import { omit } from 'lodash'
import React, { CSSProperties, forwardRef, useEffect, useState } from 'react'

import PopoverAction from './PopoverAction'

import './Popover.scss'

export interface PopoverProps extends React.ComponentPropsWithoutRef<'div'> {
  isOpen?: boolean
  arrowProps?: React.ComponentProps<'div'> & {
    innerClassName?: string
  }
  containerClassName?: string
  contentStyle?: CSSProperties
}

const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  ({ isOpen = false, arrowProps, className, children, containerClassName, contentStyle, ...props }, ref) => {
    const [isVisible, setIsVisible] = useState(isOpen)

    useEffect(() => {
      if (!isVisible && isOpen) setIsVisible(true)
    }, [isOpen])

    return (
      <FloatingPortal>
        {isVisible && (
          <div
            ref={ref}
            onAnimationEnd={() => {
              if (!isOpen) setIsVisible(false)
            }}
            className={classNames(
              'popover',
              {
                'animate-popover-show': isOpen,
                'animate-popover-hide': !isOpen,
              },
              containerClassName
            )}
            data-testid="popover-animation-container"
            {...props}
          >
            {arrowProps && (
              <div {...omit(arrowProps, 'innerClassName')} aria-label="arrow" role="presentation">
                <div className={arrowProps.innerClassName} />
              </div>
            )}
            <div className={classNames('popover__content', className)} style={contentStyle} role="menu">
              {children}
            </div>
          </div>
        )}
      </FloatingPortal>
    )
  }
)

const ExtendedPopover = Popover as typeof Popover & { Action: typeof PopoverAction }

ExtendedPopover.Action = PopoverAction

export default ExtendedPopover
