import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'

import useDetectKeyboardOpen from 'common/hooks/useDetectMobileKeyboardOpened'
import { withFlag } from 'common/users/components'
import { useDispatch, useSelector } from 'customizer/hooks'
import { actions as quoteActions, selectors as quoteSelectors } from 'customizer/quote'
import { useThemeSettings } from 'customizer/theme'
import { BottomDrawer, useBottomDrawer } from 'themes/common/components/bottomDrawer'

import QuoteBottomDrawerContent from './QuoteBottomDrawerContent'

import './QuoteBottomDrawer.scss'

export interface QuoteBottomDrawerProps {
  collapsedHeight: number
}

const QuoteBottomDrawer = ({ collapsedHeight }: QuoteBottomDrawerProps) => {
  const dispatch = useDispatch()
  const headerRef = useRef<HTMLSpanElement | null>(null)
  const footerRef = useRef<HTMLDivElement | null>(null)
  const isKeyboardOpen = useDetectKeyboardOpen()
  const isFormVisible = useSelector(quoteSelectors.quoteFormVisibilitySelector)
  const questionPanelStyle = useThemeSettings('questionPanel')
  const questionStyle = useThemeSettings('question')

  const drawer = useBottomDrawer({
    getSnapPoints: ({ minHeight, maxHeight }) => {
      const headerHeight = headerRef.current?.offsetHeight || 0
      const footerHeight = footerRef.current?.offsetHeight || 0
      return [Math.min(Math.max(minHeight + headerHeight + footerHeight, collapsedHeight), maxHeight)]
    },
  })

  useEffect(() => {
    if (isFormVisible && !drawer.isVisible) {
      drawer.show()
    }
    drawer.snap(1)
  }, [isFormVisible])

  const handleBackdropClick = () => dispatch(quoteActions.closeForm())

  if (!isFormVisible) return null

  return (
    <BottomDrawer
      className={classNames('quote-bottom-drawer quote-bottom-drawer--expanded', {
        'quote-bottom-drawer--keyboard-opened': isKeyboardOpen,
      })}
      style={{
        backgroundColor: questionPanelStyle.backgroundColor,
        color: questionStyle.questionFontColor,
        fontFamily: questionStyle.questionFontFamily?.fontFamily,
      }}
      {...drawer.props}
    >
      <BottomDrawer.Backdrop
        {...drawer.backdropProps}
        onClick={handleBackdropClick}
        className="quote-bottom-drawer__backdrop"
      />
      <QuoteBottomDrawerContent drawer={drawer} headerRef={headerRef} footerRef={footerRef} />
    </BottomDrawer>
  )
}

export default withFlag({
  Component: QuoteBottomDrawer,
  feature: 'quote_phase_1',
})
