import React, { forwardRef } from 'react'

interface QuoteBottomDrawerFooterProps {
  children: React.ReactNode
}

const QuoteBottomDrawerFooter = forwardRef<HTMLDivElement, QuoteBottomDrawerFooterProps>(({ children }, ref) => {
  return (
    <div ref={ref} className="quote-bottom-drawer__footer" aria-label="Quote bottom drawer footer">
      {children}
    </div>
  )
})

export default QuoteBottomDrawerFooter
