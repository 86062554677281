import {
  Equation,
  EquationLineType,
  CustomizableQuestion,
  NormalizedCustomizerProduct,
  QuestionInputType,
} from '@packages/types'
import { compile } from 'mathjs'

import { generateBaseSchemaForEquation } from 'common/pricing/utils'

type NormalizedCustomizerProductWithSelectedAnswer = NormalizedCustomizerProduct & {
  questions: Record<string, CustomizableQuestion>
}

export const getEquationLastQuestionLineIndex = (equation: Equation) => {
  let index = -1
  equation.lines.forEach((line, i) => {
    if (line.type === EquationLineType.Question) index = i
  })
  return index
}

export const computeEquationPriceForCustomization = (
  equation: Equation,
  product: NormalizedCustomizerProductWithSelectedAnswer
) => {
  const exec = (options?: Equation['options']) => {
    let schema = options?.schema
    if (!schema) schema = generateBaseSchemaForEquation({ lines: equation.lines })

    try {
      let formula = schema
      equation.lines.forEach((line, index) => {
        let value = line.value
        if (line.type === EquationLineType.Question) {
          const question = product.questions[value]

          if (question.isMultiAnswer) {
            if (!line.answersMap) throw new Error('answersMap not found')
            if (!question.selectedAnswers || question.selectedAnswers == null || question.selectedAnswers.length === 0)
              throw new Error('No selected answer')

            value = String(
              question.selectedAnswers.map(id => Number(line?.answersMap?.[id] || 0)).reduce((a, b) => a + b, 0)
            )
          } else {
            const answerId = question.selectedAnswer
            if (answerId == null) throw new Error('No selected answer')
            if (
              line.answersMap &&
              question.inputType !== QuestionInputType.Text &&
              question.inputType !== QuestionInputType.Number
            ) {
              value = String(line.answersMap[answerId] || 0)
            } else {
              value = String(product.answers?.[answerId]?.value || 0)
            }
          }
        }
        formula = formula.replaceAll(`$${index + 1}`, value)
      })

      if (/[^0-9\s\+\-\*\/\(\)\.]/.test(formula)) throw new Error('Invalid formula')
      const compiledFormula = compile(formula)
      const result = compiledFormula.evaluate()

      if (result === Infinity || isNaN(result)) return null
      if (result < 0) return 0
      if (options?.min && result < options.min) return options.min
      if (options?.max && result > options.max) return options.max
      return result
    } catch (e) {
      if (options?.min) return options.min
      return null
    }
  }

  let value = exec(equation?.options)

  // fallback if schema fails, try without schema
  if (value == null && equation?.options?.schema)
    value = exec({
      ...equation.options,
      schema: undefined,
    })

  return value
}

export const computeExtraPriceForQuestion = (
  question: CustomizableQuestion,
  extraPrices: Record<string, Record<string, string>>
) => {
  const extraPriceConfig = extraPrices?.[question.id]
  if (!extraPriceConfig) return 0

  if (question.isMultiAnswer) {
    return (
      question.selectedAnswers?.reduce((acc, answerId) => {
        const foundExtraPrice = extraPriceConfig?.[answerId]
        return foundExtraPrice ? parseFloat(foundExtraPrice) + acc : acc
      }, 0) || 0
    )
  }

  if (!question.selectedAnswer) return 0
  return parseFloat(extraPriceConfig?.[question.selectedAnswer])
}
