import { Field } from '@packages/types'
import React from 'react'

import './QuoteForm.scss'
import { useDispatch, useSelector } from 'customizer/hooks'
import { selectors as quoteSelectors, actions as quoteActions } from 'customizer/quote'
import { selectors as translationSelectors } from 'customizer/translations'

import QuoteFormField from './QuoteFormField'

const QuoteForm = () => {
  const dispatch = useDispatch()
  const quoteForm = useSelector(quoteSelectors.quoteFormSelector)
  const quoteFormValues = useSelector(quoteSelectors.quoteFormValuesSelector)
  const quoteFormErrors = useSelector(quoteSelectors.quoteFormErrorsSelector)
  const translation = useSelector(translationSelectors.quoteTranslationSelector)

  const updateQuoteFormValues = (key: Field['key'], value: string) => {
    dispatch(quoteActions.updateFormField(key, value))
  }

  const validateQuoteFormValue = (key: Field['key'], value: string) => {
    dispatch(quoteActions.validateFormValue(key, value))
  }

  return (
    <form className="quote-form" aria-label="Quote form">
      {quoteForm.fields.map(field => (
        <QuoteFormField
          key={field.key}
          labelField={field.key}
          id={field.key}
          name={field.key}
          type={field.inputType}
          maxLength={field.maxLength}
          value={quoteFormValues[field.key]}
          handleChange={value => updateQuoteFormValues(field.key, value)}
          onBlur={e => validateQuoteFormValue(field.key, e.target.value)}
          error={quoteFormErrors?.[field.key]}
          placeholder={translation?.[`${field.key}Placeholder`].text || translation?.[`${field.key}Placeholder`].from}
        />
      ))}
    </form>
  )
}

export default QuoteForm
