import React from 'react'

import { Select } from '../inputs'

const defaultRowsPerPage = 25

export interface TablePaginationRowsPerPageProps {
  rowsPerPage: number
  onChange: (value: number) => void
}

const TablePaginationRowsPerPage = ({
  rowsPerPage = defaultRowsPerPage,
  onChange,
}: TablePaginationRowsPerPageProps) => (
  <>
    <span className="mr-5 font-p2 text-neutral-600">Rows per page</span>
    <Select<number>
      isSearchable={false}
      onChange={value => onChange(value?.value || defaultRowsPerPage)}
      value={{ label: rowsPerPage.toString(), value: rowsPerPage }}
      options={[
        { label: '1', value: 1 },
        { label: '10', value: 10 },
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
      ]}
    />
  </>
)

export default TablePaginationRowsPerPage
