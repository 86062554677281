import React, { forwardRef } from 'react'

import { useThemeSettings } from 'customizer/theme'

interface QuoteBottomDrawerTitleProps {
  children: React.ReactNode
}

const QuoteBottomDrawerTitle = forwardRef<HTMLSpanElement, QuoteBottomDrawerTitleProps>(({ children }, ref) => {
  const questionStyle = useThemeSettings('question')

  return (
    <span
      ref={ref}
      className="quote-bottom-drawer__title"
      style={{
        color: questionStyle.questionFontColor,
        fontFamily: questionStyle.questionFontFamily?.fontFamily,
      }}
    >
      {children}
    </span>
  )
})

export default QuoteBottomDrawerTitle
