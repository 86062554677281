import React from 'react'
import { useSelector } from 'react-redux'

import { selectors as translationsSelectors } from 'customizer/translations'
import { TranslationComponent } from 'themes/common/components'

import { TranslationProps } from '../Translation'

const QuoteTranslation = (props: TranslationProps) => {
  const translations = useSelector(translationsSelectors.quoteTranslationSelector)

  return <TranslationComponent translations={translations} {...props} />
}

export default QuoteTranslation
