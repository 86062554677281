import { useSpring, to, Interpolation } from '@react-spring/web'
import { useDrag, rubberbandIfOutOfBounds } from '@use-gesture/react'
import { ReactDOMAttributes } from '@use-gesture/react/dist/declarations/src/types'
import { clamp } from 'lodash'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'

import useSnapPoints from './useSnapPoints'

interface SnapOptions {
  minSnap?: number
  maxSnap?: number
  maxHeight?: number
  snapPoints?: number[]
  y?: number
  onStart?: () => void
  onRest?: () => void
}

export interface BottomDrawerValues {
  isVisible: boolean
  snapPoints: number[]
  currentSnapPointIndex: number
  show: () => void
  dismiss: () => void
  snap: (snapPointIndex: number, options?: SnapOptions) => void
  props: {
    animatedStyle: Record<string, Interpolation<string | number, any>>
    isVisible: boolean
  }
  backdropProps: {
    animatedStyle: Record<string, Interpolation<string | number, any>>
    isVisible: boolean
  }
  contentProps: { ref: React.RefObject<HTMLDivElement> }
  draggableProps: ReactDOMAttributes
}

const useBottomDrawer = ({
  getSnapPoints,
  dismissOnSwipeDown,
}: {
  getSnapPoints?: (params: { minHeight: number; maxHeight: number }) => number[]
  dismissOnSwipeDown?: boolean
} = {}): BottomDrawerValues => {
  const contentRef = useRef<HTMLDivElement>(null)
  const { minSnap, maxSnap, maxHeight, snapPoints } = useSnapPoints(contentRef, getSnapPoints)
  const minSnapRef = useRef(minSnap)
  const maxSnapRef = useRef(maxSnap)
  const maxHeightRef = useRef(maxHeight)
  const snapPointsRef = useRef(snapPoints)
  const [currentSnapPointIndex, setCurrentSnapPointIndex] = useState(-1)
  const isVisible = currentSnapPointIndex !== -1

  const [drawerAnimation, drawerAnimationAPI] = useSpring(() => ({
    y: 0,
    minSnap,
    maxSnap,
    maxHeight,
    snapPoints,
  }))

  const snap = (snapPointIndex: number, options: SnapOptions = {}) => {
    drawerAnimationAPI.start({
      y: snapPointIndex === -1 ? 0 : snapPoints[snapPointIndex],
      minSnap: minSnap,
      maxSnap: maxSnap,
      maxHeight: maxHeight,
      snapPoints: snapPoints,
      ...options,
    })
  }

  const show = () => snap(0, { onStart: () => setCurrentSnapPointIndex(0) })

  const dismiss = () => snap(-1, { onRest: () => setCurrentSnapPointIndex(-1) })

  const handleDrag: Parameters<typeof useDrag>['0'] = eventData => {
    const currentValue = eventData.memo || drawerAnimation.y.get()

    if (eventData.tap) return currentValue

    const isDragging = eventData.down
    const movementY = eventData.movement[1] * -1
    const rawY = currentValue + movementY
    const predictedDistance = movementY * eventData.velocity[1]

    if (dismissOnSwipeDown && !isDragging && rawY + predictedDistance < minSnapRef.current / 2) {
      dismiss()
      return currentValue
    }

    let newY: number

    if (!isDragging) {
      const closestSnapPoint = snapPointsRef.current.reduce<number>((previous, current) => {
        return Math.abs(current - rawY) < Math.abs(previous - rawY) ? current : previous
      }, minSnapRef.current)

      newY = closestSnapPoint
    } else if (minSnapRef.current === maxSnapRef.current) {
      newY =
        rawY >= minSnapRef.current
          ? rubberbandIfOutOfBounds(rawY, minSnapRef.current / 2, maxSnapRef.current, 0.55)
          : rubberbandIfOutOfBounds(rawY, dismissOnSwipeDown ? 0 : minSnapRef.current, maxSnapRef.current * 2, 0.55)
    } else {
      newY = rubberbandIfOutOfBounds(rawY, dismissOnSwipeDown ? 0 : minSnapRef.current, maxSnapRef.current, 0.55)
    }

    drawerAnimationAPI.start({
      y: newY,
      maxHeight: maxHeightRef.current,
      maxSnap: maxSnapRef.current,
      minSnap: minSnapRef.current,
      snapPoints: snapPointsRef.current,
      immediate: !eventData.last,
      config: { velocity: eventData.velocity },
    })

    return currentValue
  }

  const createDraggableProps = useDrag(handleDrag, { filterTaps: true })

  const animatedHeight = drawerAnimation.y.to(y => `${Math.round(y)}px`)

  const animatedBackdropOpacity = to([drawerAnimation.y, drawerAnimation.minSnap], (y, minSnap) => {
    return minSnap ? clamp(y / minSnap, 0, 1) : 0
  })

  useLayoutEffect(() => {
    minSnapRef.current = minSnap
    maxSnapRef.current = maxSnap
    maxHeightRef.current = maxHeight
    snapPointsRef.current = snapPoints
  }, [minSnap, maxSnap, maxHeight, snapPoints])

  useEffect(() => {
    if (isVisible && minSnap > 0 && maxSnap > 0 && maxHeight > 0) {
      snap(currentSnapPointIndex)
    }
  }, [isVisible, snapPoints[currentSnapPointIndex]])

  return {
    isVisible,
    snapPoints,
    currentSnapPointIndex,
    show,
    dismiss,
    snap: (snapPointIndex: number, options: SnapOptions = {}) => {
      snap(snapPointIndex, options)
      setCurrentSnapPointIndex(snapPointIndex)
    },
    props: {
      animatedStyle: { height: animatedHeight },
      isVisible,
    },
    backdropProps: {
      animatedStyle: { opacity: animatedBackdropOpacity },
      isVisible,
    },
    contentProps: {
      ref: contentRef,
    },
    draggableProps: createDraggableProps(),
  }
}

export default useBottomDrawer
