import { useEffect, useState } from 'react'

const useDetectKeyboardOpen = (minKeyboardHeight = 250) => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false)

  useEffect(() => {
    const listener = () => {
      const newState = window.visualViewport?.height
        ? document.body.getBoundingClientRect().height - minKeyboardHeight > window.visualViewport.height
        : false

      setIsKeyboardOpen(newState)
    }

    window.visualViewport?.addEventListener('resize', listener)

    return () => window.visualViewport?.removeEventListener('resize', listener)
  }, [])

  return isKeyboardOpen
}

export default useDetectKeyboardOpen
