"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
class StringSanitizer {
    validateType(value) {
        return typeof value === 'string';
    }
    sanitize(value) {
        return (0, lodash_1.deburr)(value).replace(/\s+/g, ' ').toLocaleLowerCase();
    }
}
exports.default = new StringSanitizer();
