import { AxiosError, AxiosResponse } from 'axios'

import { NetworkError } from './types'

const errorInterceptor: [(response: AxiosResponse) => AxiosResponse, (error: AxiosError) => Promise<NetworkError>] = [
  response => response,
  error => Promise.reject(error.response || error),
]

export default errorInterceptor
