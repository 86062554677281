import classNames from 'classnames'
import React, { CSSProperties } from 'react'

import './BottomDrawerNotch.scss'

export type BottomDrawerNotchProps = React.ComponentProps<'div'> & {
  notchStyle?: CSSProperties
}

const BottomDrawerNotch = ({ className, notchStyle, ...rest }: BottomDrawerNotchProps) => {
  return (
    <div className={classNames('bottom-drawer__notch', className)} {...rest}>
      <div className="bottom-drawer__notch-handle" aria-label="notch" style={notchStyle} />
    </div>
  )
}

export default BottomDrawerNotch
