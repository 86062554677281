import classNames from 'classnames'
import React from 'react'

import './BottomDrawerContent.scss'

export type BottomDrawerContentProps = { containerClassName?: string } & React.ComponentPropsWithoutRef<'div'>

const BottomDrawerContent = React.forwardRef<HTMLDivElement, BottomDrawerContentProps>(
  ({ className, containerClassName, children, ...rest }, ref) => {
    return (
      <div className={classNames('bottom-drawer__content', className)} {...rest}>
        <div ref={ref} className={containerClassName}>
          {children}
        </div>
      </div>
    )
  }
)

export default BottomDrawerContent
