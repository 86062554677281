import { Interpolation, animated } from '@react-spring/web'
import classNames from 'classnames'
import React from 'react'
import { createPortal } from 'react-dom'

import BottomDrawerBackdrop from './BottomDrawerBackdrop'
import BottomDrawerContent from './BottomDrawerContent'
import BottomDrawerNotch from './BottomDrawerNotch'

import './BottomDrawer.scss'

interface BottomDrawerProps {
  children: React.ReactNode
  className?: string
  isVisible?: boolean
  style?: React.CSSProperties
  animatedStyle?: Record<string, Interpolation<string | number, any>>
}

const BottomDrawer = ({ children, className, isVisible, style = {}, animatedStyle = {} }: BottomDrawerProps) => {
  return createPortal(
    <animated.div
      aria-label="bottom-drawer"
      aria-modal="true"
      role="dialog"
      tabIndex={-1}
      className={classNames('bottom-drawer', { 'bottom-drawer--hidden': !isVisible }, className)}
      style={{ ...style, ...animatedStyle }}
    >
      {children}
    </animated.div>,
    document.body
  )
}

BottomDrawer.Backdrop = BottomDrawerBackdrop
BottomDrawer.Notch = BottomDrawerNotch
BottomDrawer.Content = BottomDrawerContent

export default BottomDrawer
