"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FontMimeTypes = exports.supportedFontExtensions = exports.supportedImageExtensions = exports.FontFormat = exports.FontType = void 0;
var FontType;
(function (FontType) {
    FontType["Google"] = "google";
    FontType["Custom"] = "custom";
})(FontType || (exports.FontType = FontType = {}));
var FontFormat;
(function (FontFormat) {
    FontFormat["ttf"] = "truetype";
    FontFormat["woff2"] = "woff2";
    FontFormat["woff"] = "woff";
    FontFormat["otf"] = "opentype";
})(FontFormat || (exports.FontFormat = FontFormat = {}));
const readOnlySupportedImageExtensions = ['png', 'jpg', 'jpeg', 'ai', 'eps', 'pdf', 'heic'];
exports.supportedImageExtensions = readOnlySupportedImageExtensions;
const readOnlySupportedFontExtensions = ['woff2', 'woff', 'ttf', 'otf'];
exports.supportedFontExtensions = readOnlySupportedFontExtensions;
var FontMimeTypes;
(function (FontMimeTypes) {
    FontMimeTypes["ttf"] = "font/truetype";
    FontMimeTypes["woff"] = "application/x-font-woff";
    FontMimeTypes["woff2"] = "font/woff2";
    FontMimeTypes["otf"] = "font/opentype";
})(FontMimeTypes || (exports.FontMimeTypes = FontMimeTypes = {}));
