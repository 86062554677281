import { sortBy } from 'lodash'
import { useState } from 'react'

import { useObserveDimensionsOnResize } from 'common/hooks'

const useSnapPoints = (
  contentRef: React.RefObject<HTMLElement | null>,
  geSnapPoints?: (state: { minHeight: number; maxHeight: number }) => number[]
) => {
  const [minHeight, setMinHeight] = useState(contentRef.current?.offsetHeight || 0)
  const [maxHeight, setMaxHeight] = useState(window.innerHeight)

  useObserveDimensionsOnResize(contentRef, ({ height }) => setMinHeight(height))
  useObserveDimensionsOnResize({ current: document.body }, ({ height }) => setMaxHeight(height))

  const snapPoints = geSnapPoints ? geSnapPoints({ minHeight, maxHeight }) : [minHeight]
  const orderedSnapPoints = sortBy(snapPoints)

  return {
    minSnap: orderedSnapPoints[0],
    maxSnap: orderedSnapPoints[orderedSnapPoints.length - 1],
    maxHeight,
    snapPoints: orderedSnapPoints,
  }
}

export default useSnapPoints
