import classNames from 'classnames'
import React from 'react'

import { AnswerStyle } from 'themes/common/types'

export interface WithAnswerThemeProps {
  id: string
  enabled?: boolean
  selected: boolean
  extraClassNames?: string
  handleClick?: (id: string) => void
  answerStyle?: AnswerStyle
}

interface WithAnswerThemeAddedProps {
  extraClassNames: string
  handleClick: (id: string) => void
  style: AnswerStyle & {
    borderColor: string
    borderWidth: string
    fontFamily: string
    color: string
    fontSize: string
  }
}

const withAnswerTheme = <Props extends WithAnswerThemeProps>(
  Wrapped: React.ComponentType<Props & WithAnswerThemeAddedProps>
) => {
  const displayName = Wrapped.displayName || Wrapped.name || 'Component'

  const ComponentWithAnswerTheme = (props: Props) => {
    const { selected, enabled, extraClassNames, answerStyle } = props

    const handleClick = () => {
      if (props.enabled) props.handleClick?.(props.id)
    }

    const updatedExtraClassNames = classNames(extraClassNames, {
      selected: selected,
      disabled: enabled === false,
    })

    const updatedAnswerStyle = {
      ...answerStyle,
      borderColor: selected ? answerStyle?.selectedAnswerBorderColor : answerStyle?.unselectedAnswerBorderColor,
      borderWidth: selected ? answerStyle?.selectedAnswerBorderWidth : answerStyle?.unselectedAnswerBorderWidth,
      fontFamily: answerStyle?.answerFontFamily?.fontFamily,
      color: answerStyle?.answerFontColor,
      fontSize: answerStyle?.answerFontSize,
    }

    return (
      <Wrapped
        {...(props as any)}
        extraClassNames={updatedExtraClassNames}
        onClick={handleClick}
        style={updatedAnswerStyle}
      />
    )
  }

  ComponentWithAnswerTheme.displayName = `withTheme(${displayName})`

  return ComponentWithAnswerTheme
}

export default withAnswerTheme
