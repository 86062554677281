import DefaultStoreStrategy from './DefaultStoreStrategy'
import ShopifyStrategy from './ShopifyStrategy'

export default (eCommerce, pricing) => {
  let strategy

  switch (eCommerce) {
    case 'shopify':
      strategy = ShopifyStrategy
      break
    case 'woocommerce':
    case 'prestashop':
    case 'custom-store':
    default:
      strategy = DefaultStoreStrategy
      break
  }

  return strategy(pricing)
}
