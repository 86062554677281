import { Interpolation, animated } from '@react-spring/web'
import classNames from 'classnames'
import React from 'react'
import { createPortal } from 'react-dom'

import './BottomDrawerBackdrop.scss'

interface BottomDrawerBackdropProps extends React.ComponentPropsWithoutRef<'div'> {
  isVisible?: boolean
  animatedStyle: Record<string, Interpolation<string | number, any>>
}

const BottomDrawerBackdrop = ({ animatedStyle, className, isVisible, ...rest }: BottomDrawerBackdropProps) => {
  if (!isVisible) return null

  return createPortal(
    <animated.div
      className={classNames('bottom-drawer-backdrop', className)}
      aria-label="backdrop"
      style={animatedStyle}
      {...rest}
    />,
    document.body
  )
}

export default BottomDrawerBackdrop
