import React from 'react'

const STYLE = {
  minWidth: '60px',
  minHeight: '60px',
}

interface SVGThumbnailProps {
  id: string
  image?: { url: string }
  color?: string
}

const SVGThumbnail = ({ id, image, color }: SVGThumbnailProps) => {
  return (
    <svg style={STYLE} width="110%" height="110%">
      <filter id={`filter-${id}`} filterUnits="objectBoundingBox" x="-5%" y="-5%" width="110%" height="110%">
        <feFlood x="0" y="0" width="100%" height="100%" in="SourceGraphic" result="colorRectangle" floodColor={color} />
        <feBlend mode="multiply" in2="colorRectangle" in="SourceGraphic" />
      </filter>
      {image ? (
        <image
          preserveAspectRatio="xMidYMid slice"
          xlinkHref={image.url}
          width="100%"
          height="100%"
          filter={color ? `url(#filter-${id})` : ''}
          crossOrigin="anonymous"
        />
      ) : (
        <rect x="0" y="0" width="110%" height="110%" fill={color} />
      )}
    </svg>
  )
}

export default SVGThumbnail
