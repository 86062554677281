"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnswerType = exports.OverflowBehavior = exports.TextAlign = exports.TextVerticalAlign = exports.TextPositionType = void 0;
var TextPositionType;
(function (TextPositionType) {
    TextPositionType["Bezier"] = "bezier";
    TextPositionType["Box"] = "box";
})(TextPositionType || (exports.TextPositionType = TextPositionType = {}));
var TextVerticalAlign;
(function (TextVerticalAlign) {
    TextVerticalAlign["Top"] = "top";
    TextVerticalAlign["Middle"] = "middle";
    TextVerticalAlign["Bottom"] = "bottom";
})(TextVerticalAlign || (exports.TextVerticalAlign = TextVerticalAlign = {}));
var TextAlign;
(function (TextAlign) {
    TextAlign["Left"] = "left";
    TextAlign["Center"] = "center";
    TextAlign["Right"] = "right";
})(TextAlign || (exports.TextAlign = TextAlign = {}));
var OverflowBehavior;
(function (OverflowBehavior) {
    OverflowBehavior["Visible"] = "visible";
    OverflowBehavior["Clip"] = "clip";
    OverflowBehavior["Resize"] = "resize";
})(OverflowBehavior || (exports.OverflowBehavior = OverflowBehavior = {}));
var AnswerType;
(function (AnswerType) {
    AnswerType["Material"] = "material";
    AnswerType["Image"] = "image";
    AnswerType["Color"] = "color";
    AnswerType["Text"] = "text";
    AnswerType["Logo"] = "logo";
    AnswerType["LogoPosition"] = "logoPosition";
    AnswerType["Position"] = "position";
    AnswerType["Font"] = "font";
    AnswerType["FontSize"] = "fontSize";
    AnswerType["Value"] = "value";
    AnswerType["PrintAreaLogoPosition"] = "printAreaLogoPosition";
    AnswerType["PrintAreaTextPosition"] = "printAreaTextPosition";
    AnswerType["Outline"] = "outline";
})(AnswerType || (exports.AnswerType = AnswerType = {}));
