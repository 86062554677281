import testCanvasSizeForBrowser from './testCanvasSizeForBrowser'

export const dimensionStrings = {
  mini: '20x20',
  regular: '200x200',
  large: '500x500',
}

export const getThumbnailDimensionString = (minWidth?: number | string | null) => {
  if (minWidth == null || Number(minWidth) <= 200) return dimensionStrings.regular
  return dimensionStrings.large
}

const supportedTypes = ['png', 'jpg', 'jpeg']
const convertableTypes = ['ai', 'eps', 'pdf', 'heic']

const defaultBreakPoint = { width: 1536, height: 864, dimensionString: '1536x864' }

// Important: when adding new breakpoints make sure that the lambda supports them
// in AWS resize lambda add the dimensionString to the ALLOWED_RESOLUTIONS env var
const verticalScreenSizeBreakPoints = [
  { width: 560, height: 640, dimensionString: '560x640' },
  { width: 1536, height: 864, dimensionString: '1536x864' },
  { width: 1920, height: 1080, dimensionString: '1920x1080' },
  { width: 2560, height: 1440, dimensionString: '2560x1440' },
].sort((breakPointA, breakPointB) => {
  const breakPointATotalSize = breakPointA.width * breakPointA.height
  const breakPointBTotalSize = breakPointB.width * breakPointB.height

  if (breakPointATotalSize > breakPointBTotalSize) return 1

  if (breakPointATotalSize < breakPointBTotalSize) return -1

  return 0
})

export const getScreenSizeBreakPoint = (screenWidth: number, screenHeight: number) => {
  const validBreakPoints = verticalScreenSizeBreakPoints.filter(breakPoint =>
    testCanvasSizeForBrowser({ width: breakPoint.width, height: breakPoint.height })
  )

  if (validBreakPoints.length === 0) {
    console.warn('Could not find a valid breakPoint for canvas size returning default value')
    return defaultBreakPoint
  }

  const breakPoint = validBreakPoints.find(dimensions => {
    return screenWidth <= dimensions.width && screenHeight <= dimensions.height
  })

  if (breakPoint === undefined && testCanvasSizeForBrowser({ width: screenWidth, height: screenHeight })) return

  return breakPoint ?? validBreakPoints[validBreakPoints.length - 1]
}

type BuildOptions = {
  preventResize?: boolean
  dimensionString?: string
  convertFrom?: string
}

class ImageUrlBuilder {
  build(src: string, options: BuildOptions = { preventResize: false }) {
    let url

    try {
      url = new URL(src)
    } catch (error) {
      url = new URL(`${location.protocol}//${location.host}${src}`)
    }

    if (window.TRANSFORM_SOURCE && typeof window.TRANSFORM_SOURCE === 'string') url.host = window.TRANSFORM_SOURCE

    const fileType = url.pathname.split('.').pop()

    const convertFrom = options.convertFrom || convertableTypes.includes(fileType!) ? fileType : null

    if (convertFrom) {
      url.searchParams.append('convertFrom', convertFrom)
      url.pathname = url.pathname.replace(`.${fileType}`, '.png')
    }

    if (options.preventResize) return url.toString()

    const dimensionString =
      options.dimensionString || getScreenSizeBreakPoint(window.screen.width, window.screen.height)?.dimensionString

    if (!dimensionString) return url.toString()

    const urlParts = url.pathname.split('/')

    urlParts.splice(urlParts.length - 1, 0, `${dimensionString}`)
    url.pathname = urlParts.join('/')

    return url.toString()
  }
}

class ImageLoader {
  urlBuilder: ImageUrlBuilder

  constructor() {
    this.urlBuilder = new ImageUrlBuilder()
  }

  loadImage(src = '', options = { preventResize: false }) {
    if (src == 'none.png' || src == '') return this.emptyImagePromise()

    const buildedSrc = this.buildSrc(src, options)

    return this.fetchImage(buildedSrc)
  }

  fetchImage(src: string) {
    const fileType = new URL(src).pathname.split('.').pop()
    if (!supportedTypes.includes(fileType!)) return this.emptyImagePromise()

    return fetchUsingHTMLImgElement(src)
  }

  async getSize(src: string) {
    const img = await this.loadImage(src, { preventResize: true })
    return { width: img.width, height: img.height }
  }

  buildSrc(src: string, options?: BuildOptions) {
    return this.urlBuilder.build(src, options)
  }

  emptyImagePromise() {
    return new Promise<HTMLImageElement>(resolve => {
      resolve(new Image())
    })
  }
}

const fetchUsingHTMLImgElement = (src: string) => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    if (src === '') reject(new Error('Image not found: ' + src))

    const img: HTMLImageElement & { initialSrc?: string } = document.createElement('img')
    img.setAttribute('crossOrigin', 'Anonymous')

    img.onload = function () {
      resolve(img)
    }

    img.onerror = function () {
      reject(new Error('Image not found: ' + src))
    }
    img.initialSrc = src
    img.src = src
  })
}

const imageLoader = new ImageLoader()

export default imageLoader
