import currencies from '@packages/data/currencies.json'

const formatCurrency = (amount: number, intl: { currency: string; language: string; country: string }) => {
  try {
    const local = `${intl.language}-${intl.country}`
    return new Intl.NumberFormat(local, { style: 'currency', currency: intl.currency }).format(amount)
  } catch (error) {
    try {
      return new Intl.NumberFormat(intl.language, { style: 'currency', currency: intl.currency }).format(amount)
    } catch (error) {
      const currency = currencies[intl.currency as keyof typeof currencies]

      if (!currency) return `${intl.currency}${amount.toFixed(2)}`

      return `${currency.symbol}${amount.toFixed(currency.decimals)}`
    }
  }
}

const formatMoney = (
  amount: number,
  options: {
    currency?: string | null
    language?: string | null
    country?: string | null
    includeSign?: boolean | null
  } = {}
): string => {
  const formattedCurrency = formatCurrency(amount, {
    currency: options.currency || 'USD',
    language: options.language || 'en',
    country: options.country || 'US',
  })

  return options.includeSign && amount >= 0 ? `+${formattedCurrency}` : formattedCurrency
}

export default formatMoney
