import classNames from 'classnames'
import React from 'react'
import { MoveableState } from 'react-moveable'

import { pathUtils } from 'common/drawing'

import { getOffsetBezier } from '../utils'

export interface PathButtonProps extends React.ComponentProps<'div'> {
  moveableState: MoveableState
  offset?: { x: number; y: number }
}

const PathControl = ({ moveableState, children, className, offset = { x: 0, y: 0 }, ...rest }: PathButtonProps) => {
  const { target } = moveableState

  const path = target?.getAttributeNS(null, 'd') ?? ''

  const bezier = pathUtils.pathToBezier(path)

  const offsetBezier = getOffsetBezier(bezier, moveableState)

  let x = 0
  let minY = Number.MAX_VALUE
  let maxY = Number.MIN_VALUE

  for (let i = 0; i < offsetBezier.length; i = i + 2) {
    if (offsetBezier[i] > x) x = offsetBezier[i]

    if (offsetBezier[i + 1] > maxY) maxY = offsetBezier[i + 1]
    if (offsetBezier[i + 1] < minY) minY = offsetBezier[i + 1]
  }

  const y = (Math.abs(maxY) - Math.abs(minY)) / 2 + offset.y

  return (
    <div
      className={classNames('absolute', className)}
      style={{
        transform: `translate(${x + offset.x + 20}px, ${y}px) translateY(-50%)`,
      }}
      {...rest}
    >
      {children}
    </div>
  )
}

export default PathControl
