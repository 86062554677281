import React from 'react'

import { useThemeSettings } from 'customizer/theme'
import Remove from 'icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'

import './ModalHeader.scss'

export interface ModalHeaderProps {
  title: string
  onClose: () => void
}

const ModalHeader = ({ title, onClose }: ModalHeaderProps) => {
  const questionStyle = useThemeSettings('question')
  const modalStyle = useThemeSettings('modal')

  return (
    <div className="modal-header">
      <span style={{ color: questionStyle.questionFontColor }}>{title}</span>
      <button role="button" onClick={onClose}>
        <Remove
          aria-label="close modal"
          className="modal-header__close"
          style={{ fill: modalStyle.closeButtonColor }}
        />
      </button>
    </div>
  )
}

export default ModalHeader
