import React, { useRef } from 'react'

import { useMobileScrollAxisLock } from 'common/hooks'
import useDetectKeyboardOpen from 'common/hooks/useDetectMobileKeyboardOpened'
import { useDispatch, useSelector } from 'customizer/hooks'
import { actions as quoteActions, selectors as quoteSelectors } from 'customizer/quote'
import { QuoteRequestStatus } from 'customizer/quote/types'
import { BottomDrawer, QuoteForm, useBottomDrawer } from 'themes/common/components'

import ActionButton from '../ActionButton'
import QuoteTranslation from '../QuoteTranslation'
import QuoteBottomDrawerFooter from './QuoteBottomDrawerFooter'
import QuoteBottomDrawerTitle from './QuoteBottomDrawerTitle'

interface QuoteBottomDrawerContentProps {
  drawer: ReturnType<typeof useBottomDrawer>
  headerRef: React.RefObject<HTMLSpanElement>
  footerRef: React.RefObject<HTMLDivElement>
}

const QuoteBottomDrawerContent = ({ drawer, headerRef, footerRef }: QuoteBottomDrawerContentProps) => {
  const dispatch = useDispatch()
  const isKeyboardOpen = useDetectKeyboardOpen()
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const { handlers, ref } = useMobileScrollAxisLock({ scrollRef })
  const quoteRequestStatus = useSelector(quoteSelectors.quoteRequestStatusSelector)
  const quoteHasErrors = useSelector(quoteSelectors.quoteHasErrorsSelector)
  const quoteId = useSelector(quoteSelectors.quoteIdSelector)

  switch (quoteRequestStatus) {
    case QuoteRequestStatus.success:
      return (
        <>
          <QuoteBottomDrawerTitle ref={headerRef}>
            <QuoteTranslation field="quoteRequestSuccess" />
          </QuoteBottomDrawerTitle>
          <BottomDrawer.Content>
            <div {...handlers} ref={ref} className="quote-bottom-drawer__content" id="quote-bottom-drawer-content">
              <div ref={drawer.contentProps.ref} className="quote-bottom-drawer__feedback">
                <QuoteTranslation
                  field="quoteRequestSuccessFeedback"
                  className="quote-bottom-drawer__feedback--message"
                />
                <div className="quote-bottom-drawer__feedback--quoteIdMessage">
                  <QuoteTranslation field="quoteIdFeedback" className="quote-bottom-drawer__feedback--message" />
                  <span className="quote-bottom-drawer__feedback--quoteId">{` QT${quoteId}`}</span>
                </div>
              </div>
            </div>
          </BottomDrawer.Content>

          <QuoteBottomDrawerFooter ref={footerRef}>
            <ActionButton onClick={() => parent.postMessage({ eventName: 'kickflipKeepShopping' }, '*')}>
              <QuoteTranslation field="keepShopping" />
            </ActionButton>
          </QuoteBottomDrawerFooter>
        </>
      )
    case QuoteRequestStatus.error:
      return (
        <>
          <QuoteBottomDrawerTitle ref={headerRef}>
            <QuoteTranslation field="quoteRequestError" />
          </QuoteBottomDrawerTitle>
          <BottomDrawer.Content>
            <div {...handlers} ref={ref} className="quote-bottom-drawer__content" id="quote-bottom-drawer-content">
              <div ref={drawer.contentProps.ref} className="quote-bottom-drawer__feedback">
                <QuoteTranslation
                  field="quoteRequestErrorFeedback"
                  className="quote-bottom-drawer__feedback--message"
                />
              </div>
            </div>
          </BottomDrawer.Content>

          <QuoteBottomDrawerFooter ref={footerRef}>
            <ActionButton onClick={() => dispatch(quoteActions.retry())}>
              <QuoteTranslation field="retry" />
            </ActionButton>
          </QuoteBottomDrawerFooter>
        </>
      )
    case QuoteRequestStatus.idle:
    case QuoteRequestStatus.loading:
    default:
      return (
        <>
          <QuoteBottomDrawerTitle ref={headerRef}>
            <QuoteTranslation field="requestQuote" />
          </QuoteBottomDrawerTitle>
          <BottomDrawer.Content>
            <div {...handlers} ref={ref} className="quote-bottom-drawer__content" id="quote-bottom-drawer-content">
              <div ref={drawer.contentProps.ref} className="quote-bottom-drawer__form">
                <QuoteForm />
              </div>
            </div>
          </BottomDrawer.Content>

          {isKeyboardOpen ? null : (
            <QuoteBottomDrawerFooter ref={footerRef}>
              <ActionButton
                onClick={() => dispatch(quoteActions.submitQuote())}
                isDisabled={quoteRequestStatus === 'loading' || quoteHasErrors}
                isLoading={quoteRequestStatus === 'loading'}
              >
                <QuoteTranslation field="submit" />
              </ActionButton>
            </QuoteBottomDrawerFooter>
          )}
        </>
      )
  }
}

export default QuoteBottomDrawerContent
