import React from 'react'

import PluginCustomComponentContext from './PluginCustomComponentContext'

interface PluginComponentProps {
  defaultComponent?: React.ComponentType
  componentKey: string
  [x: string]: any
}

export default class PluginComponent extends React.Component<PluginComponentProps> {
  static defaultProps = {
    defaultComponent: () => null,
  }

  override render() {
    return (
      <PluginCustomComponentContext.Consumer>
        {components => {
          const Component = components[this.props.componentKey]
          if (Component) return <Component pluginId={Component.pluginId!} {...this.props} />
          if (this.props.defaultComponent) return <this.props.defaultComponent />
          return null
        }}
      </PluginCustomComponentContext.Consumer>
    )
  }
}
