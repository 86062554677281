import React from 'react'

import { useDispatch, useSelector } from 'customizer/hooks'
import { actions as quoteActions, selectors as quoteSelectors } from 'customizer/quote'
import { QuoteRequestStatus } from 'customizer/quote/types'
import { selectors as translationsSelectors } from 'customizer/translations'
import { Modal, QuoteForm } from 'themes/common/components'

import ActionButton from '../ActionButton'
import QuoteTranslation from '../QuoteTranslation'

const QuoteModalContent = () => {
  const dispatch = useDispatch()
  const quoteTranslations = useSelector(translationsSelectors.quoteTranslationSelector)
  const quoteRequestStatus = useSelector(quoteSelectors.quoteRequestStatusSelector)
  const quoteHasErrors = useSelector(quoteSelectors.quoteHasErrorsSelector)
  const quoteId = useSelector(quoteSelectors.quoteIdSelector)

  const handleCloseModal = () => dispatch(quoteActions.closeForm())

  switch (quoteRequestStatus) {
    case QuoteRequestStatus.success:
      return (
        <>
          <Modal.Header
            title={quoteTranslations.quoteRequestSuccess.text || quoteTranslations.quoteRequestSuccess.from}
            onClose={handleCloseModal}
          />
          <div className="quote-modal__feedback-message">
            <QuoteTranslation field="quoteRequestSuccessFeedback" />
            <div className="quote-modal__feedback-quoteIdMessage">
              <QuoteTranslation field="quoteIdFeedback" />
              <span className="quote-modal__feedback-quoteId">{` QT${quoteId}`}</span>
            </div>
          </div>
          <div className="quote-modal__footer">
            <ActionButton onClick={() => parent.postMessage({ eventName: 'kickflipKeepShopping' }, '*')}>
              <QuoteTranslation field="keepShopping" />
            </ActionButton>
          </div>
        </>
      )
    case QuoteRequestStatus.error:
      return (
        <>
          <Modal.Header
            title={quoteTranslations.quoteRequestError.text || quoteTranslations.quoteRequestError?.from}
            onClose={handleCloseModal}
          />
          <div className="quote-modal__feedback-message">
            <QuoteTranslation field="quoteRequestErrorFeedback" />
          </div>
          <div className="quote-modal__footer">
            <ActionButton onClick={() => dispatch(quoteActions.retry())}>
              <QuoteTranslation field="retry" />
            </ActionButton>
          </div>
        </>
      )
    case QuoteRequestStatus.loading:
    case QuoteRequestStatus.idle:
    default:
      return (
        <>
          <Modal.Header
            title={quoteTranslations.requestQuote.text || quoteTranslations.requestQuote.from}
            onClose={handleCloseModal}
          />
          <div className="quote-modal__content">
            <QuoteForm />
          </div>
          <div className="quote-modal__footer">
            <ActionButton
              onClick={() => dispatch(quoteActions.submitQuote())}
              isDisabled={quoteRequestStatus === 'loading' || quoteHasErrors}
              isLoading={quoteRequestStatus === 'loading'}
            >
              <QuoteTranslation field="submit" />
            </ActionButton>
          </div>
        </>
      )
  }
}

export default QuoteModalContent
