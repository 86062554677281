import currencies from '@packages/data/currencies.json'
import { curry, pipe } from 'lodash/fp'

export const applyTaxes = curry((pricing, price) => {
  return pricing.applyTaxes ? price * ((100 + Number(pricing.taxes)) / 100) : price
})

export const applyExchangeRate = curry((pricing, price) => {
  return pricing.rate ? price * Number(pricing.rate) : price
})

export const applyRounding = curry((pricing, price) => {
  return Number(Number(price).toFixed(currencies[pricing.currency].decimals))
})

export default pricing => ({
  applyPriceModifiers: pipe(applyTaxes(pricing), applyExchangeRate(pricing), applyRounding(pricing)),
})
