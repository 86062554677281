import Konva from 'konva'
import { isEqual } from 'lodash'

import { Point, getCenterFromPoints, getDistanceFromPoints } from 'utils/math'

import { ImageNode, Node, MultilineTextNode, TextOnPathNode, ClippingGroup, MultiAnswerGroup, TextNode } from '../types'
import GroupClientRectClip from './GroupClientRectClip'
import ImagePart from './ImagePart'
import MultiAnswer from './MultiAnswer'
import MultilineTextPart from './MultilineTextPart'
import Part from './Part'
import PrintArea from './PrintArea'
import TextOnPathPart from './TextOnPathPart'
import ViewContainer from './ViewContainer'

type Parent = ViewContainer | PrintArea | MultiAnswer

Konva.autoDrawEnabled = false

export default class ProductStage extends Konva.Stage {
  mainLayer: Konva.Layer
  unzoomedScale
  productDimension
  previousScaleAnimation?: Konva.Animation
  xBeforeMove?: number
  yBeforeMove?: number
  widthDiff?: number
  heightDiff?: number

  constructor(config: Konva.StageConfig) {
    super(config)

    this.unzoomedScale = 1
    this.productDimension = { width: 1, height: 1 }
    this.mainLayer = new Konva.Layer()
    this.add(this.mainLayer)
  }

  setUnzoomedScale(scale: number) {
    this.unzoomedScale = scale
  }

  getUnzoomedScale() {
    return this.unzoomedScale
  }

  getViewContainers() {
    return this.mainLayer.getChildren().filter(child => (child as ViewContainer).isViewContainer) as ViewContainer[]
  }

  setProductDimensions(dimensions: { width: number; height: number }) {
    this.productDimension = dimensions
  }

  getProductDimensions() {
    return this.productDimension
  }

  getViewContainer(view: number) {
    return this.findOne<ViewContainer>(`#view-${view}`)
  }

  removeNode(id: string) {
    this.getViewContainers().forEach(viewContainer => {
      viewContainer.removeNodeFromCache(id)
      viewContainer.findOne(`#${id}`)?.destroy()
    })
  }

  renderImagePart = async (node: ImageNode, parent: Parent) => {
    let imagePart = parent.getViewContainer()!.findOne<ImagePart>(`#${node.id}`)

    if (!(node as ImageNode).image?.src) {
      imagePart?.destroy()
      return
    }

    if (!imagePart) {
      imagePart = new ImagePart({ boundingBoxStyle: this.attrs.boundingBoxStyle, image: undefined })
    }

    if ((imagePart.getParent() as unknown as Parent) !== parent) parent.add(imagePart)

    imagePart.id(node.id)

    await imagePart.render(node)

    return imagePart
  }

  renderTextPart = async (node: TextOnPathNode | MultilineTextNode, parent: Parent) => {
    const viewContainer = parent.getViewContainer() as ViewContainer
    let textPart: TextOnPathPart | MultilineTextPart | null = viewContainer.findOne<TextOnPathPart | MultilineTextPart>(
      `#${node.id}`
    )

    if (!node.text.value) {
      textPart?.destroy()
      return
    }

    if (textPart) {
      if (
        (!(node as MultilineTextNode).position.shape && textPart instanceof MultilineTextPart) ||
        ((node as MultilineTextNode).position.shape && !(textPart instanceof MultilineTextPart))
      ) {
        textPart.destroy()
        textPart = null
      }
    }

    if (!textPart) {
      textPart = (node as MultilineTextNode).position.shape
        ? new MultilineTextPart({ boundingBoxStyle: this.attrs.boundingBoxStyle, image: undefined })
        : new TextOnPathPart({ boundingBoxStyle: this.attrs.boundingBoxStyle, image: undefined })
    }

    if ((textPart.getParent() as unknown as Parent) !== parent) parent.add(textPart)

    textPart.id(node.id)

    await textPart.render(node as any)

    return textPart
  }

  renderPrintArea(node: ClippingGroup, parent: ViewContainer) {
    let printAreaGroup = parent.findOne<PrintArea>(`#${node.id}`)

    if (!printAreaGroup) {
      printAreaGroup = new PrintArea()
      printAreaGroup.id(node.id)
      parent.add(printAreaGroup)
    }

    printAreaGroup.render(node)

    return printAreaGroup.clippingGroup as GroupClientRectClip
  }

  renderMultiAnswerGroup(node: MultiAnswerGroup, parent: ViewContainer) {
    let multiAnswerGroup = parent.findOne<MultiAnswer>(`#${node.id}`)

    if (!multiAnswerGroup) {
      multiAnswerGroup = new MultiAnswer({ id: `${node.id}` })
    }

    if ((multiAnswerGroup.getParent() as unknown as Parent) !== parent) parent.add(multiAnswerGroup)

    return multiAnswerGroup
  }

  cachePart(part?: Part) {
    if (!part) return
    const boundaries = part.getBoundaries()
    if (boundaries?.width && boundaries.height) {
      part.cache({
        x: boundaries.x,
        y: boundaries.y,
        width: boundaries.width,
        height: boundaries.height,
      })
    }
  }

  async renderNode(node: Node, parent: Parent, cache = true): Promise<boolean> {
    let part: Part | undefined
    const viewContainer = parent.getViewContainer?.()

    if (!viewContainer) return false

    if (!isEqual(viewContainer.getNodeFromCache(node.id), node)) {
      viewContainer.cacheNode(node)

      switch (node.type) {
        case 'image': {
          part = await this.renderImagePart(node, parent)
          break
        }
        case 'text': {
          part = await this.renderTextPart(node, parent)
          break
        }
        case 'clippingGroup': {
          const clippingGroup = this.renderPrintArea(node, parent as ViewContainer)
          const nodeResults = await Promise.all(node.objects.map(node => this.renderNode(node, clippingGroup, cache)))
          return nodeResults.includes(true)
        }
        case 'multiAnswerGroup': {
          const group = this.renderMultiAnswerGroup(node, parent as ViewContainer)
          const nodeResults = await Promise.all(node.objects.map(node => this.renderNode(node, group, cache)))

          return nodeResults.includes(true)
        }
      }
    } else {
      switch (node.type) {
        case 'clippingGroup':
        case 'multiAnswerGroup': {
          const parent = viewContainer.findOne(`#${node.id}`)
          if (!parent) break

          const nodeResults = await Promise.all(
            node.objects.map(node => this.renderNode(node, parent as ViewContainer, cache))
          )
          return nodeResults.includes(true)
        }
        default: {
          part = !['clippingGroup', 'multiAnswerGroup'].includes(node.type)
            ? parent.findOne<Part>(`#${node.id}`)
            : undefined
        }
      }
    }

    if (part?.getParent?.()) {
      await part.applyPostProcessingOperations(node as TextNode | ImageNode)

      if (cache && (part.dirty || (!part._cache.has('canvas') && part.width() !== 0 && part.height() !== 0))) {
        this.cachePart(part)

        part.dirty = false
        return true
      }
    }

    return false
  }

  calculateCenteringOffsets(scale: Konva.Vector2d) {
    const stageAndProductWidthDifference = this.width() - this.productDimension.width * scale.x
    const stageAndProductHeightDifference = this.height() - this.productDimension.height * scale.y

    return {
      offsetX: -stageAndProductWidthDifference / 2 / scale.x,
      offsetY: -stageAndProductHeightDifference / 2 / scale.y,
    }
  }

  centerProduct() {
    this.setAttrs(this.calculateCenteringOffsets(this.scale()!))
  }

  animateScale(targetScale: number) {
    this.previousScaleAnimation?.stop()

    const currentScale = this.scale()!.x
    const totalDelta = targetScale - currentScale

    const clamp = (scale: number) => Math.min(Math.max(scale, this.getUnzoomedScale()), 1)
    const animation = new Konva.Animation(frame => {
      const delta = totalDelta / (100 / frame!.timeDiff)

      const newScaleX = clamp(this.scale()!.x + delta)
      const newScaleY = clamp(this.scale()!.y + delta)

      this.setAttrs({
        scaleX: newScaleX,
        scaleY: newScaleY,
        ...this.calculateCenteringOffsets({ x: newScaleX, y: newScaleY }),
      })

      if (newScaleX === targetScale && newScaleY === targetScale) animation.stop()
    }, this.getLayers())

    animation.start()

    this.previousScaleAnimation = animation
  }

  zoomIn() {
    this.animateScale(1)
  }

  zoomOut() {
    this.animateScale(this.getUnzoomedScale())
  }

  resetCurrentViewPositionIfNeeded() {
    const resetTween = new Konva.Tween({
      node: this.mainLayer,
      duration: 0.1,
      x: this.xBeforeMove,
      y: this.yBeforeMove,
      onFinish: () => {
        resetTween.destroy()
      },
    }).play()
  }

  handlePan() {
    const productDimensions = this.getProductDimensions()

    this.xBeforeMove = this.mainLayer.x()
    this.yBeforeMove = this.mainLayer.y()
    this.widthDiff = productDimensions.width - this.width()
    this.heightDiff = productDimensions.height - this.height()

    this.on('mousemove', () => {
      const pos = this.getPointerPosition()!

      const xRatio = pos.x / this.width()
      const yRatio = pos.y / this.height()

      if (this.widthDiff! > 0) this.mainLayer.x(this.widthDiff! / 2 + this.xBeforeMove! - xRatio * this.widthDiff!)

      if (this.heightDiff! > 0) this.mainLayer.y(this.heightDiff! / 2 + this.yBeforeMove! - yRatio * this.heightDiff!)
      this.mainLayer.batchDraw()
    })
  }

  handlePinch() {
    let lastCenter: Point | null = null
    let lastDist = 0

    this.on('touchmove', e => {
      const touch1 = e.evt.touches[0]
      const touch2 = e.evt.touches[1]

      if (touch1 && touch2) {
        e.evt.preventDefault()

        const p1 = {
          x: touch1.clientX,
          y: touch1.clientY,
        }
        const p2 = {
          x: touch2.clientX,
          y: touch2.clientY,
        }

        if (!lastCenter) {
          lastCenter = getCenterFromPoints([p1, p2])
          return
        }
        const newCenter = getCenterFromPoints([p1, p2])

        const dist = getDistanceFromPoints(p1, p2)

        if (!lastDist) {
          lastDist = dist
        }

        const pointTo = {
          x: (newCenter.x - this.x()) / this.scaleX(),
          y: (newCenter.y - this.y()) / this.scaleX(),
        }

        const scale = this.scaleX() * (dist / lastDist)

        this.scaleX(scale)
        this.scaleY(scale)

        const dx = newCenter.x - lastCenter.x
        const dy = newCenter.y - lastCenter.y

        const newPos = {
          x: newCenter.x - pointTo.x * scale + dx,
          y: newCenter.y - pointTo.y * scale + dy,
        }

        this.position(newPos)
        this.draw()

        lastDist = dist
        lastCenter = newCenter
      }
    })

    this.on('touchend', () => {
      const scale = this.getUnzoomedScale()

      lastDist = 0
      lastCenter = null
      this.scaleX(scale)
      this.scaleY(scale)
      this.position({ x: 0, y: 0 })
      this.draw()
    })
  }

  removePinch() {
    this.off('touchmove')
    this.off('touchend')
  }

  removePan() {
    this.off('mousemove')
    this.resetCurrentViewPositionIfNeeded()
  }

  resetMove() {
    this.xBeforeMove = undefined
    this.yBeforeMove = undefined
  }

  renderViewContainer(view: number) {
    const container = new ViewContainer({ view, width: this.width(), height: this.height() })

    this.mainLayer.add(container)

    return container
  }

  removeGrid() {
    this.findOne('#grid')?.destroy()
  }

  renderGrid() {
    this.removeGrid()

    const blockSnapSize = 30
    const width = this.width() / this.scaleX()
    const height = this.height() / this.scaleY()
    const gridLayer = new Konva.Layer()
    gridLayer.id('grid')
    const padding = blockSnapSize

    for (let i = 0; i < width / padding; i++) {
      gridLayer.add(
        new Konva.Line({
          points: [Math.round(i * padding) + 0.5, 0, Math.round(i * padding) + 0.5, height],
          stroke: '#333333',
          opacity: 0.3,
          strokeWidth: 1,
        })
      )
    }

    gridLayer.add(new Konva.Line({ points: [0, 0, 10, 10] }))
    for (let j = 0; j < height / padding; j++) {
      gridLayer.add(
        new Konva.Line({
          points: [0, Math.round(j * padding), width, Math.round(j * padding)],
          stroke: '#333333',
          opacity: 0.3,
          strokeWidth: 0.5,
        })
      )
    }
    gridLayer.x(this.offsetX())
    gridLayer.y(this.offsetY())

    this.add(gridLayer)
  }
}
