import classNames from 'classnames'
import React, { useState } from 'react'

import { twoDDisplayerSelectors } from 'customizer/2dDisplayer'
import { useSelector } from 'customizer/hooks'
import { useThemeSettings } from 'customizer/theme'

import CircularLoadingIcon from '../CircularLoadingIcon'

import './ActionButton.scss'

interface ActionButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isDisabled?: boolean
  children: React.ReactNode
  isLoading?: boolean
}

const ActionButton = ({ className, isDisabled, children, isLoading, ...props }: ActionButtonProps) => {
  const [hover, setHover] = useState(false)
  const style = useThemeSettings('actionSection')
  const isTouch = useSelector(twoDDisplayerSelectors.isTouchSelector)

  return (
    <button
      className={classNames('quote-action-button', className)}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      style={{
        fontFamily: style.fontFamily?.fontFamily,
        color: style.color,
        backgroundColor: style.backgroundColor,
        fontSize: style.fontSize,
        borderWidth: style.buttonsBorderWidth,
        borderStyle: 'solid',
        borderColor: style.buttonsBorderColor,
        borderRadius: style.buttonsBorderRadius,
        ...(hover && !isTouch ? { backgroundColor: style.hoverBackgroundColor } : {}),
        ...(isDisabled ? { opacity: 0.4, cursor: 'default', backgroundColor: style.backgroundColor } : {}),
      }}
      disabled={isDisabled}
      {...props}
    >
      {isLoading ? <CircularLoadingIcon style={style} /> : children}
    </button>
  )
}

export default ActionButton
