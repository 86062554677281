import { Able, MoveableManagerInterface, Renderer } from 'react-moveable'

import { pathUtils } from 'common/drawing'

import { PathMoveableProps } from '../types'
import { getOffsetBezier } from '../utils'

interface Renderable extends Able {
  props: readonly 'style'[]
  render: (moveable: MoveableManagerInterface<PathMoveableProps>, React: Renderer) => JSX.Element
}

const PathRenderable: Renderable = {
  name: 'customRenderable',
  props: ['style'] as const,
  events: [] as const,
  always: true,
  render: (moveable, React) => {
    const { target, height, width } = moveable.getState()
    const path = target?.getAttributeNS(null, 'd') ?? ''

    const bezier = pathUtils.pathToBezier(path)
    const offsetBezier = getOffsetBezier(bezier, moveable.getState())

    const d = pathUtils.bezierToPath(offsetBezier)
    const leftX = offsetBezier[0]
    const leftY = offsetBezier[1]
    const rightX = offsetBezier[6]
    const rightY = offsetBezier[7]

    const ctrl1X = offsetBezier[2]
    const ctrl1Y = offsetBezier[3]

    const ctrl2X = offsetBezier[4]
    const ctrl2Y = offsetBezier[5]

    return (
      <svg
        key="text-editor-svg"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          top: '0px',
          left: '0px',
          position: 'absolute',
          zIndex: 1,
          overflow: 'visible',
          width: `${width}px`,
          height: `${height}px`,
          minWidth: '1px',
          minHeight: '1px',
        }}
      >
        <g>
          <path fill="none" className="stroke-2 stroke-primary-500" xmlns="http://www.w3.org/2000/svg" d={d} />
          <line
            xmlns="http://www.w3.org/2000/svg"
            x1={ctrl1X}
            y1={ctrl1Y}
            x2={leftX}
            y2={leftY}
            className="stroke-primary-500"
            strokeWidth="2"
            strokeDasharray="5 3"
            strokeLinecap="round"
            pointerEvents="all"
          />
          <line
            xmlns="http://www.w3.org/2000/svg"
            x1={ctrl2X}
            y1={ctrl2Y}
            x2={rightX}
            y2={rightY}
            className="stroke-primary-500"
            strokeWidth="2"
            strokeDasharray="5 3"
            strokeLinecap="round"
            pointerEvents="all"
          />
        </g>
      </svg>
    )
  },
}

export default PathRenderable
