import { curry, pipe } from 'lodash/fp'

import { applyTaxes, applyExchangeRate, applyRounding } from './DefaultStoreStrategy'

export const applyPriceAjustement = curry((pricing, price) => {
  if (!pricing.priceAdjustment) return price
  return (pricing.priceAdjustment / 100 + 1) * price
})

export const applyRoundUp = curry((pricing, price) => {
  if (!pricing.applyRounding || !pricing.rounding || price === 0 || pricing.currency === pricing.defaultCurrency) {
    return price
  }

  let integerFactor = 1

  if (pricing.rounding <= 1) {
    integerFactor = 100
  } else if (String(price).includes('.')) {
    const numberOfDecimals = String(price).split('.')[1].length
    integerFactor = Number(`1${'0'.repeat(numberOfDecimals)}`)
  }

  const adjustedRounding = Math.round(pricing.rounding * integerFactor)
  const adjustedPrice = Math.round(price * integerFactor)
  const numberOfDigits = String(adjustedRounding).length
  const priceRoundingPart = Number(String(adjustedPrice).slice(-numberOfDigits))

  let roundingGap

  if ([10, 100, 1000].includes(adjustedRounding)) {
    const rest = priceRoundingPart % adjustedRounding
    roundingGap = rest === 0 ? 0 : adjustedRounding - rest
  } else {
    roundingGap = adjustedRounding - priceRoundingPart
  }

  if (roundingGap === 0) return adjustedPrice / integerFactor

  if (roundingGap > 0) return (adjustedPrice + roundingGap) / integerFactor

  return (adjustedPrice + (Number(`1${'0'.repeat(numberOfDigits)}`) + roundingGap)) / integerFactor
})

export default pricing => ({
  applyPriceModifiers: pipe(
    applyTaxes(pricing),
    applyPriceAjustement(pricing),
    applyExchangeRate(pricing),
    applyRoundUp(pricing),
    applyRounding(pricing)
  ),
})
