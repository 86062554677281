import classNames from 'classnames'
import React, { ForwardedRef, forwardRef } from 'react'

interface PopoverActionProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  className?: string
  children: React.ReactNode
}

const PopoverAction = forwardRef(
  ({ className, disabled, onClick, children, ...rest }: PopoverActionProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      e.preventDefault()
      onClick?.(e)
    }

    return (
      <button
        ref={ref}
        onClick={handleClick}
        disabled={disabled}
        className={classNames('popover__action', { 'popover__action--disabled': disabled }, className)}
        {...rest}
      >
        {children}
      </button>
    )
  }
)

export default PopoverAction
