import React from 'react'
import { createPortal } from 'react-dom'
import { RemoveScroll } from 'react-remove-scroll'

import ModalHeader from './ModalHeader'

import './Modal.scss'

export interface ModalProps {
  children: React.ReactNode
  className?: string
  onBackdropClick?: () => void
  style?: React.CSSProperties
}

const Modal = ({ children, className, onBackdropClick, style }: ModalProps) =>
  createPortal(
    <RemoveScroll>
      <div onClick={onBackdropClick} className="modal__backdrop" role="presentation" aria-label="backdrop" />
      <div role="dialog" className={className} onClick={e => e.stopPropagation()} style={style}>
        {children}
      </div>
    </RemoveScroll>,
    document.body
  )

Modal.Header = ModalHeader

export default Modal
